import Head from 'next/head'

import { config } from 'src/config'

import type { ImgHTMLAttributes } from 'react'
import type { Image, MediaImage } from 'src/data/graphql-generated'

export interface ShopifyImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  image: Partial<Image | MediaImage['image']> | null | undefined
  width: number
  height: number
  lazy?: boolean
  preload?: boolean
}

export const ShopifyImage = ({ image, width, height, lazy = false, preload = false, ...rest }: ShopifyImageProps) => {
  if (!image) {
    return (
      <img
        src={`https://cdn.coffeecircle.com/${config.imagePlaceholderUploadcareId}/-/resize/${width}x${height}/-/quality/lightest/-/progressive/yes/no-image.png`}
        alt="not available"
        width={width}
        height={height}
        aria-hidden
        {...rest}
      />
    )
  }

  const src = `${image.url}?width=${width}&height=${height}`
  const srcSet = `${image.url}?width=${width}&height=${height}, ${image.url}?width=${width * 2}&height=${height * 2} 2x`

  return (
    <>
      {preload && (
        <Head>
          <link key={src} rel="preload" as="image" href={src} imageSrcSet={srcSet} />
        </Head>
      )}
      <img
        src={src}
        srcSet={srcSet}
        alt={image.altText || ''}
        width={width}
        height={height}
        {...(lazy && { loading: 'lazy' })}
        {...(preload && { fetchpriority: 'high' })}
        {...rest}
      />
    </>
  )
}
