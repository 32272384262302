// import differenceInDays from 'date-fns/differenceInDays'

import { config } from 'src/config'
import { Badge } from 'src/enum/Badge'
import { getCalculatedPrices } from 'src/functions/price/getCalculatedPrices'
import { getMetafield } from 'src/functions/util/getMetafield'

import type { Product, ProductVariant } from 'src/data/graphql-generated'

type BadgeSize = 'normal' | 'large'

export const getBadgeVariant = (
  product: Product,
  variant: ProductVariant,
  badgeSize: BadgeSize = 'normal',
): Badge | null => {
  const badgeValue = getMetafield(product.metafields, 'product', 'badge')

  // manually set badge values
  if (badgeValue) {
    if (badgeValue === Badge.promoted) return Badge.promoted
    if (badgeValue === Badge.coffeeOfTheMonth) return Badge.coffeeOfTheMonth
    if (badgeValue === Badge.blackFriday) return Badge.blackFriday
    if (badgeValue === Badge.new) return Badge.new
  }

  // auto set sale badge
  if (variant.compareAtPrice) return Badge.sale

  // check for free shipping condition (only applicable for large badges)
  if (badgeSize === 'large') {
    const { price } = getCalculatedPrices({ variant })
    return Number(price.amount) >= config.shippingFreeFrom ? Badge.freeShipping : null
  }

  return null
}
