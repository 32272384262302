import { config } from 'src/config'
import donationPrices from 'src/data/_static/donationPrices.json'

import type { CartLineCost, MoneyV2, ProductVariant } from 'src/data/graphql-generated'
import type { CalculatedPrices } from 'src/types/Product'

export const getPricesWithDonation = (
  donationVariantId: string,
  donationQuantity: number,
  variant: ProductVariant,
  cartLineCost?: CartLineCost,
): CalculatedPrices => {
  type DonationPrices = { [id: string]: MoneyV2 }
  const baseDonationPrice = (donationPrices as DonationPrices)[donationVariantId]

  if (!baseDonationPrice) {
    throw new Error(
      `Product "${variant.product.title}" has donation set on its variant(s), but price mapping couldn't be found for donation variant "${donationVariantId}".`,
    )
  }

  const donationPriceAmount = parseFloat(baseDonationPrice.amount) * donationQuantity || 0
  const subscriptionDiscount = config.defaultSubscriptionDiscount

  const price = {
    ...variant.price,
    amount: (parseFloat((cartLineCost?.totalAmount || variant.price).amount) + donationPriceAmount).toFixed(2),
  }
  const priceBeforeDiscount = getPriceBeforeDiscount(cartLineCost, donationPriceAmount)

  const compareAtPrice = getCompareAtPrice(variant, donationPriceAmount)
  const { unitPrice, subscriptionUnitPrice } = getUnitPrices(variant, donationPriceAmount, subscriptionDiscount)
  const subscriptionPrice = getSubscriptionPrice(variant, donationPriceAmount, subscriptionDiscount)

  const donationPrice = {
    ...baseDonationPrice,
    amount: donationPriceAmount.toFixed(2),
  }

  return {
    price,
    priceBeforeDiscount,
    compareAtPrice,
    unitPrice,
    subscriptionPrice,
    subscriptionUnitPrice,
    donationPrice,
  }
}

const getPriceBeforeDiscount = (
  cartLineCost: CartLineCost | undefined,
  donationPriceAmount: number,
): MoneyV2 | null => {
  if (!cartLineCost?.subtotalAmount) return null

  return {
    ...cartLineCost.subtotalAmount,
    amount: (parseFloat(cartLineCost.subtotalAmount.amount) + donationPriceAmount).toFixed(2),
  }
}

const getCompareAtPrice = (variant: ProductVariant, donationPriceAmount: number): MoneyV2 | null => {
  if (!variant.compareAtPrice) return null

  return {
    ...variant.compareAtPrice,
    amount: (parseFloat(variant.compareAtPrice.amount) + donationPriceAmount).toFixed(2),
  }
}

const getUnitPrices = (
  variant: ProductVariant,
  donationPriceAmount: number,
  subscriptionDiscount: number,
): { unitPrice: MoneyV2 | null; subscriptionUnitPrice: MoneyV2 | null } => {
  if (!(variant.unitPrice && variant.unitPriceMeasurement)) {
    return {
      unitPrice: null,
      subscriptionUnitPrice: null,
    }
  }

  const { quantityValue, quantityUnit, referenceValue, referenceUnit } = variant.unitPriceMeasurement
  const unitPriceAmount = parseFloat(variant.unitPrice.amount)

  // Settings > Store Details > Default Weight Unit is "kg", so conversion is needed.
  const quantity = quantityUnit === 'G' ? quantityValue / 1000 : quantityValue
  const reference = referenceUnit === 'G' ? referenceValue / 1000 : referenceValue
  const multiplier = reference / quantity

  return {
    unitPrice: {
      ...variant.unitPrice,
      amount: (unitPriceAmount + donationPriceAmount * multiplier).toFixed(2),
    },
    subscriptionUnitPrice: {
      ...variant.unitPrice,
      amount: (
        unitPriceAmount -
        (unitPriceAmount * subscriptionDiscount) / 100 +
        donationPriceAmount * multiplier
      ).toFixed(2),
    },
  }
}

const getSubscriptionPrice = (
  variant: ProductVariant,
  donationPriceAmount: number,
  subscriptionDiscount: number,
): MoneyV2 => {
  const {
    price: { amount },
  } = variant

  return {
    ...variant.price,
    amount: (parseFloat(amount) - (parseFloat(amount) * subscriptionDiscount) / 100 + donationPriceAmount).toFixed(2),
  }
}
