import { Modal as MantineModal, LoadingOverlay, Button } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconX } from '@tabler/icons-react'
import React from 'react'

import { config } from 'src/config'

import styles from './Modal.module.scss'

import type {
  ModalProps as MantineModalProps,
  ButtonProps as MantineButtonProps,
  LoaderProps as MantineLoaderProps,
} from '@mantine/core'

const noop = () => {}

interface HeaderStartButtonProps extends MantineButtonProps {
  icon?: React.ReactNode
  onClick?: () => void
}

interface ModalProps extends MantineModalProps {
  footer?: React.ReactNode
  loading?: boolean
  loaderProps?: MantineLoaderProps
  closeableWhileLoading?: boolean
  headerStartButtonProps?: HeaderStartButtonProps
}

export const Modal = ({
  portalProps = {},
  closeButtonProps = {},
  loaderProps = {},
  headerStartButtonProps = {},
  centered = true,
  size = 'lg',
  radius = 'sm',
  loading = false,
  closeableWhileLoading = false,
  children,
  title,
  zIndex = 300,
  ...props
}: ModalProps) => {
  const isMobile = !!useMediaQuery(`(max-width: ${config.breakpoints.md}px)`)
  const isClosingDisabled = !closeableWhileLoading && loading

  return (
    <MantineModal.Root
      portalProps={{
        ...portalProps,
        className: styles.portal,
      }}
      size={size}
      centered={centered}
      radius={radius}
      fullScreen={isMobile}
      transitionProps={{ transition: isMobile ? 'slide-up' : 'fade' }}
      zIndex={zIndex}
      {...props}
      onClose={isClosingDisabled ? noop : props.onClose}
    >
      <MantineModal.Overlay />

      <MantineModal.Content>
        <MantineModal.Header>
          {!!headerStartButtonProps.icon && (
            <Button
              className={styles.headerStartButton}
              onClick={headerStartButtonProps.onClick}
              {...headerStartButtonProps}
            >
              {headerStartButtonProps.icon}
            </Button>
          )}

          <MantineModal.Title>{title}</MantineModal.Title>

          {!isClosingDisabled && <MantineModal.CloseButton {...closeButtonProps} icon={<IconX stroke={1.6} />} />}
        </MantineModal.Header>

        <MantineModal.Body>
          <LoadingOverlay visible={loading} loaderProps={loaderProps} />

          <div className={styles.body}>{children}</div>

          {!!props.footer && <div className={styles.footer}>{props.footer}</div>}
        </MantineModal.Body>
      </MantineModal.Content>
    </MantineModal.Root>
  )
}
