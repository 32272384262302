import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { useState } from 'react'

import { ShopifyImage } from 'src/components/ShopifyImage/ShopifyImage'

import styles from './ProductCardImage.module.scss'

import type { ComponentType } from 'react'
import type { ProductCardHoverImageProps } from 'src/components/ProductCard/ProductCardHoverImage'
import type { Image } from 'src/data/graphql-generated'

interface ProductCardImageProps {
  name: string
  image: Image | null
  hover?: Partial<Image>
  preload?: boolean
  withPadding?: boolean
}

export const ProductCardImage = ({
  name,
  image,
  hover,
  preload = false,
  withPadding = true,
}: ProductCardImageProps) => {
  const [isHover, setIsHover] = useState<boolean>(false)

  const ProductCardHoverImage: ComponentType<ProductCardHoverImageProps> = dynamic(() =>
    import('./ProductCardHoverImage').then((mod) => mod.ProductCardHoverImage),
  )

  return (
    <div
      onMouseEnter={() => {
        setIsHover(true)
      }}
    >
      <ShopifyImage
        className={clsx(styles.productImg, withPadding && styles.withPadding)}
        image={image}
        width={275}
        height={275}
        alt={name}
        title={name}
        preload={preload}
        lazy={!preload}
      />
      {hover && isHover && <ProductCardHoverImage image={hover} />}
    </div>
  )
}
