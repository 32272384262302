import type { Product, ProductVariant } from 'src/data/graphql-generated'
import type { Language } from 'src/enum/Language'

export const pathHelper = (
  productType: Product['productType'],
  language: Language,
  handle: Product['handle'],
  sku?: ProductVariant['sku'],
  isVariant?: boolean,
  hasMultipleVariants?: boolean,
) => {
  const pathPrefix = productType === 'coffee' ? 'k' : 'p'
  const path = `/${language}/${pathPrefix}/${handle}${hasMultipleVariants && isVariant ? `?number=${sku}` : ''}`

  return path
}
