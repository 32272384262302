import { getPricesWithDonation } from 'src/functions/price/getPricesWithDonation'
import { getMetafield } from 'src/functions/util/getMetafield'

import type { CartLineCost, ProductVariant } from 'src/data/graphql-generated'
import type { CalculatedPrices } from 'src/types/Product'

interface GetCalculatedPricesParams {
  variant: ProductVariant
  donationQuantity?: number
  cartLineCost?: CartLineCost
}

export const getCalculatedPrices = (functionParams: GetCalculatedPricesParams): CalculatedPrices => {
  const { variant, donationQuantity = 1, cartLineCost } = functionParams

  const donationVariantId = getMetafield(variant.metafields, 'donation', 'variant')

  if (!donationVariantId) {
    return {
      price: cartLineCost?.totalAmount || variant.price,
      priceBeforeDiscount: cartLineCost?.subtotalAmount || null,
      compareAtPrice: variant.compareAtPrice || null,
      unitPrice: variant.unitPrice || null,
      subscriptionPrice: cartLineCost?.totalAmount || variant.price,
      subscriptionUnitPrice: variant.unitPrice || null,
      donationPrice: null,
    }
  }

  return getPricesWithDonation(donationVariantId, donationQuantity, variant, cartLineCost)
}
