import { ActionIcon } from '@mantine/core'
import { IconInfoSmall } from '@tabler/icons-react'
import clsx from 'clsx'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal } from 'src/components/Modal/Modal'
import { Text } from 'src/components/Text/Text'

import styles from './RoastDegree.module.scss'

interface RoastDegreeProps {
  value: number
  withModal?: boolean
  centered?: boolean
}

export const RoastDegree = ({ value, withModal, centered }: RoastDegreeProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation()

  const type = 'roastDegree'
  const modalTitle = t(`coffee.${type}`)
  const modalText = t(`coffee.${type}ModalText`)

  return (
    <div className={styles.container}>
      <div className={clsx(styles.roastLevelBase, centered && styles.centered)}>
        <div className={styles.roastChip01} />
        <div className={styles.roastChip02} />
        <div className={styles.roastChip03} />
        <div
          aria-hidden
          className={clsx(styles.pill, centered && styles.centered)}
          // eslint-disable-next-line no-nested-ternary
          style={{ left: `calc(${value * 11.1}% - ${value > 6 ? '3px' : value > 3 ? '2px' : '1px'})` }}
        />
      </div>
      {withModal && (
        <ActionIcon
          variant="light"
          color="grey"
          size="sm"
          onClick={() => setModalOpen(true)}
          aria-label={`${modalTitle} info`}
          data-analytics-id="coffee-detail-info"
        >
          <IconInfoSmall stroke={1.6} />
        </ActionIcon>
      )}
      {withModal && (
        <Modal title={modalTitle} opened={modalOpen} onClose={() => setModalOpen(false)}>
          <Text variant="p">{modalText}</Text>
        </Modal>
      )}
    </div>
  )
}
