import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { Price } from 'src/components/Price/Price'
import { getCalculatedPrices } from 'src/functions/price/getCalculatedPrices'

import styles from './ProductCardPrice.module.scss'

import type { ProductVariant } from 'src/data/graphql-generated'

interface ProductCardPriceProps {
  productVariant: ProductVariant
  isStartingPrice?: boolean
  isLarge?: boolean
}

export const ProductCardPrice = ({ productVariant, isLarge, isStartingPrice }: ProductCardPriceProps) => {
  const { t } = useTranslation()

  const { unitPriceMeasurement } = productVariant
  const { price, compareAtPrice, unitPrice } = getCalculatedPrices({ variant: productVariant })

  const hasComparedAtPrice = compareAtPrice?.amount
  const hasUnitPrice = unitPrice?.amount

  return (
    <div className={clsx(styles.wrapper, isLarge && styles.large)}>
      {isStartingPrice && <span className={styles.startingPrice}>{`${t('common.from')} `}</span>}
      <span className={clsx(styles.price, hasComparedAtPrice && styles.newPrice)}>
        <Price price={price} />
      </span>{' '}
      {hasComparedAtPrice && (
        <span className={styles.oldPrice}>
          <Price price={compareAtPrice} />
        </span>
      )}{' '}
      {hasUnitPrice && (
        <span className={styles.metadata}>
          (<Price price={unitPrice} unitPriceMeasurement={unitPriceMeasurement} />)
        </span>
      )}
    </div>
  )
}
